<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ updateMode == false ? $t('message.addZohoReport') : $t('message.updateZohoReport') + ": " + zohoReportName }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form
                    lazy-validation
                    ref="zohoReportForm"
                    v-model="validForm"
                >
                    <v-layout row pt-3>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.title') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :value="ZohoReport.title"
                                    :rules="[rules.title]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="ZohoReport.title = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.tabName') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :value="ZohoReport.tab_title"
                                    :rules="[rules.tabTitle]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="ZohoReport.tab_title = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.embeddedCode') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-lazy>
                                <v-textarea
                                        :rules="[]"
                                        :value="ZohoReport.embed_link"
                                        autocomplete="off"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        auto-grow
                                        rows="1"
                                        solo
                                        @change="ZohoReport.embed_link = $event"
                                />
                            </v-lazy>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.filter') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-checkbox
                                    :label="$t('message.yes')"
                                    :true-value="1"
                                    :false-value="0"
                                    class="mt-0"
                                    dense
                                    hide-details="auto"
                                    v-model="ZohoReport.filter_field"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addZohoReport()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveZohoReport()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {api} from 'Api';
import {mapGetters} from "vuex";

export default {
    name: "AddZohoReport",
    props: ['zohoReportId','zohoReportName','dialog','updateMode','dialog-closed','update-done','create-done'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            loading: {
                add: false,
                get: false,
                save: false
            },
            edit_dialog: false,
            // rules: {
            //     title: v => !!v || this.$t('message.required'),
            //     tabTitle: v => !!v || this.$t('message.required'),
            //     embedLink: v => !!v || this.$t('message.required')
            // },
            validForm: true,
            ZohoReport: {
                id: null,
                filter_field: 0,
                title: null,
                tab_title: null,
                embed_link: null,
            }
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        addZohoReport() {
            if(this.$refs.zohoReportForm.validate()){
                this.loading.add = true
                api
                    .post("/admin/zoho-reports",{
                        zohoReport: this.ZohoReport
                    })
                    .then(response => {
                        if(response.data.status == 'success'){
                            this.$toast.success(this.$t('message.successes.zohoReportAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.zohoReportNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('message.errors.zohoReportNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.zohoReportNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        getZohoReportToUpdateById() {
            return new Promise((resolve, reject) => {
                api
                    .get('/admin/zoho-reports/' + this.zohoReportId)
                    .then(response => {
                        let data = response.data.data[0]
                        this.ZohoReport.id = data.ZohoReport.id
                        this.ZohoReport.title = data.ZohoReport.title
                        this.ZohoReport.tab_title = data.ZohoReport.tab_title
                        this.ZohoReport.embed_link = data.ZohoReport.embed_link
                        this.ZohoReport.filter_field = data.ZohoReport.filter_field
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        resetZohoReportForm() {
            this.ZohoReport.id = null
            this.ZohoReport.title = null
            this.ZohoReport.tab_title = null
            this.ZohoReport.embed_link = null
            this.ZohoReport.filter_field = 0
        },
        saveZohoReport () {
            if(this.$refs.zohoReportForm.validate()){
                this.loading.save = true
                api
                    .put("/admin/zoho-reports/" + this.zohoReportId,{
                        zohoReport: this.ZohoReport
                    })
                    .then(response => {
                        if(response.data.status == 'success'){
                            this.$toast.success(this.$t('message.successes.zohoReportUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.zohoReportNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('message.errors.zohoReportNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.zohoReportNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getZohoReportToUpdateById(this.zohoReportId)
                        .then( () => {
                            this.loading.get = false
                        })
                        .catch(() => {
                            this.loading.get = false
                        })
                }
            } else {
                this.resetZohoReportForm()
            }
            this.edit_dialog = value
        }
    },
}
</script>

<style scoped>

</style>